var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-search","label":"Suche","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"green","dark":""},on:{"click":function($event){return _vm.onNewClicked()}}},[_vm._v(" Neuer Tag ")])],1),_c('v-data-table',{staticClass:"test-1",attrs:{"headers":_vm.headers,"items":_vm.getItems,"search":_vm.search,"items-per-page":_vm.itemsPerPage,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.color",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.color}},[_vm._v(" "+_vm._s(item.color)+" ")])]}},{key:"item.categoryId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCategoryName(item.categoryId))+" ")]}},{key:"item.isPublic",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.isPublic),callback:function ($$v) {_vm.$set(item, "isPublic", $$v)},expression:"item.isPublic"}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1),_c('v-snackbar',{attrs:{"color":"error","y":"top","timeout":_vm.timeout},model:{value:(_vm.showError),callback:function ($$v) {_vm.showError=$$v},expression:"showError"}},[_vm._v(" "+_vm._s('Es ist ein Fehler adasdasufgetreten')+" ")]),_c('create-update-modal',{ref:"createUpdateModal",on:{"dialogClose":_vm.onRefresh}}),_c('delete-modal',{ref:"deleteModal",on:{"refresh":_vm.onRefresh}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }