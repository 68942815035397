<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="290">
        <v-card>
          <v-card-title class="headline">Eintrag löschen</v-card-title>

          <v-card-text>
            Willst du den unwiderruflich Eintrag wirklich löschen?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn tile color="grey darken-1" text @click="cancel()">
              Abbrechen
            </v-btn>

            <v-btn tile color="red darken-1" text @click="onDeleteClick()">
              Löschen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-snackbar v-model="showError" color="error" y="top" :timeout="timeout">
      {{ 'Es ist ein  Fehler aufgetreten' }}
    </v-snackbar>
    <v-snackbar
      v-model="showSuccess"
      color="success"
      y="top"
      :timeout="timeout"
    >
      {{ 'Der Tag wurde erfolgreich gelöscht' }}
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data: () => ({
    API_URL: process.env.VUE_APP_API,
    dialog: false,
    showError: false,
    showSuccess: false,
    responseObj: null,
    timeout: 3000,
    id: null,
  }),

  computed: {},

  methods: {
    onDeleteClick() {
      axios
        .delete(`${this.API_URL}basic/event-of-the-week/${this.id}/`)
        .then(() => {
          this.dialog = false;
          this.$emit('refresh');
        })
        .catch(() => {
          this.showError = true;
        });
    },
    deleteItemById() {},
    show(id) {
      this.dialog = true;
      this.id = id;
    },
    cancel() {
      this.dialog = false;
      this.$emit('dialogClose');
    },
  },
};
</script>
