var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getItems,"items-per-page":_vm.itemsPerPage,"expanded":_vm.expanded,"hide-default-footer":"","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.createdAt).format('DD.MM.YYYY'))+" ")]}},{key:"item.isPublic",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.isPublic ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.isPublic ? 'mdi-check-circle' : 'mdi-close-circle'))])]}},{key:"item.isProcessed",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.isProcessed ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.isProcessed ? 'mdi-check-circle' : 'mdi-close-circle'))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('ActionButtonMenu',{attrs:{"data":item}})]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('v-card',{staticClass:"ma-2",attrs:{"flat":""}},[_vm._v(" "+_vm._s(_vm.resultJson(item.experimentItem))+" ")])],1)]}}])})],1),_c('delete-modal',{ref:"deleteMessageModal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }